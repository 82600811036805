import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper
} from "@mui/material";
import Layout from "../components/layout";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";


const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const tagHeader = `${tag}`;
  const { edges } = data.allMessagesJson;
 
  return (
    <>
      <Layout title={`Items tagged ${tag}`} url={`/tags/${tag}`} imgSrc={edges[0].node.imageURL.publicURL}>
        <h1>{tagHeader}</h1>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

        <ImageList gap={32} cols={2} sx={{mb:2}}>
          {edges.map((item) => {
              const image = getImage(item.node.imageURL);

            return (
              <Link to={item.node.path}>
              <Paper elevation={3} sx={{mb:1}}>
                <ImageListItem key={item.node.id} >
                <GatsbyImage image={image} alt={item.node.altText} />
                  <ImageListItemBar title={item.node.title} position="below" sx= {{px:2, pt:0.5}} />
                </ImageListItem>
              </Paper>
              </Link>
            );
          })}
        </ImageList>
        </Box>

        {/* small viewport  */}
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>

        <ImageList gap={32} cols={1} sx={{mb:2}}>
          {edges.map((item) => {
            const image = getImage(item.node.imageURL);
            return (
              <Link to={item.node.path}>
              <Paper elevation={3} sx={{mb:1}}>
                <ImageListItem key={item.node.id} >
                <GatsbyImage image={image} alt={item.node.altText} />
                  <ImageListItemBar title={item.node.title} position="below" sx= {{px:2, pt:0.5}} />
                </ImageListItem>
              </Paper>
              </Link>
            );
          })}
        </ImageList>
        </Box>
      </Layout>
    </>
  );
};

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMessagesJson: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            altText: PropTypes.string.isRequired,
            imageURL: PropTypes.shape({
              publicURL: PropTypes.string.isRequired,
              childImageSharp: PropTypes.shape({
                gatsbyImageData: PropTypes.object,
              }),
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default Tags;

export const pageQuery = graphql`
  query ($tag: String) {
    allMessagesJson(
      filter: { tags: { in: [$tag] } }
      sort: { fields: createdDate, order: DESC }
    ) {
      edges {
        node {
          id
          path
          title
          altText
          imageURL {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
